// // import React, { useState, useEffect, useCallback } from "react";
// // import { Calendar, momentLocalizer } from "react-big-calendar";
// // import "react-big-calendar/lib/css/react-big-calendar.css";
// // import moment from "moment";
// // import Events from "./monthevents";

// // const localizer = momentLocalizer(moment);

// // const MonthCalendar = () => {
// //   const [eventsData, setEventsData] = useState([]);
// //   const [currentDate, setCurrentDate] = useState(new Date());
// //   const [selectedDate, setSelectedDate] = useState(new Date());
// //   const [selectedView, setSelectedView] = useState("month");
// //   const [reload, setReload] = useState(false);

// //   const handleSelect = ({ start }) => {
// //     setSelectedDate(start);
// //   };

// //   const getEventData = async () => {
// //     try {
// //       setEventsData(Events);
// //     } catch (error) {
// //       console.error("Error setting events data:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     getEventData();
// //   }, [currentDate, reload, selectedView]);


// //   const dayPropGetter = useCallback(
// //     (date) => {
// //       const eventDay = moment(date).format("D");

// //       const matchingEvent = eventsData.find(
// //         (e) => moment(e.start).format("D") === eventDay
// //       );

// //       if (matchingEvent) {
// //         const backgroundColor =
// //           matchingEvent.type === "holiday" ? "red" : "#fff";

// //         return {
// //           style: {
// //             backgroundColor,
// //             color: "white",
// //           },
// //         };
// //       }

// //       return {};
// //     },
// //     [eventsData]
// //   );

// //   const eventStyleGetter = useCallback(
// //     (event) => {
// //       const backgroundColor =
// //         event.type === "holiday" ? "red" : "#fff";

// //       return {
// //         style: {
// //           backgroundColor, 
// //         },
// //       };
// //     },
// //     []
// //   );

// //   return (
// //     <div className="app h-screen month-calendar">
// //       <div className="calendar-container h-[300px] w-[300px] mx-10 justify-center">
// //         <Calendar
// //           popup
// //           dayPropGetter={dayPropGetter}
// //           eventStyleGetter={eventStyleGetter}
// //           style={{ height: "350px", width: "350px" }}
// //           allDayMaxRows={1}
// //           localizer={localizer}
// //           onNavigate={(date) => setSelectedDate(date)}
// //           events={eventsData}
// //           defaultDate={currentDate}
// //           onSelectSlot={handleSelect}
// //           titleAccessor={(event) => (
// //             <span>
// //               {event.type === "birthday" ? (
// //                 <i class="fa-solid fa-cake-candles fa-lg p-0 text-red-400"></i>
// //               ) : event.type === "anniversary" ? (
// //                 <i className="fa-solid fa-calendar-days fa-lg p-0 text-blue-400">
// //                   {" "}
// //                 </i>
// //               ) : event.type === "holiday" ? (
// //                 event.title
// //               ) : null}
// //             </span>
// //           )}
// //           tooltipAccessor={(event) =>
// //             event.title && event.description
// //               ? "Title: " + event.title + ", Description: " + event.description
// //               : "Title: " + event.title
// //           }
// //           components={{
// //             toolbar: (toolbarProps, onNavigate) => (
// //               <>
// //                 <div className="text-gray-600">
// //                   <div className="flex justify-between items-center">
// //                     <div className="flex items-center">
// //                       <div className="rbc-toolbar mt-[10px] ml-5">
// //                         <button
// //                           type="button"
// //                           className="bg-blue-600 text-gray-600 font-bold py-1 px-2 mr-2 cursor-pointer"
// //                           title="Today"
// //                           onClick={() => toolbarProps.onNavigate("TODAY")}
// //                         >
// //                           Today
// //                         </button>

// //                         <i
// //                           className="fa-solid fa-chevron-left py-1 pl-2 mr-2 cursor-pointer"
// //                           title="Previous"
// //                           onClick={() => toolbarProps.onNavigate("PREV")}
// //                         ></i>

// //                         <i
// //                           className="fa-solid fa-chevron-right py-1 px-2 cursor-pointer"
// //                           title="Next"
// //                           onClick={() => toolbarProps.onNavigate("NEXT")}
// //                         ></i>
// //                       </div>
// //                       <div className="rbc-toolbar ml-2">
// //                         <span className="rbc-toolbar-label text-[14px] mt-[10px]">
// //                           {moment(selectedDate).format("MMMM YYYY")}
// //                         </span>
// //                       </div>
// //                     </div>
// //                   </div>
// //                 </div>
// //                 <hr className="w-full p-0 m-0" />
// //               </>
// //             ),
// //           }}
// //           views={{
// //             month: true,
// //             timeGrid: {
// //               eventLimit: 1,
// //             },
// //           }}
// //           view={selectedView}
// //         />
// //       </div>
// //     </div>
// //   );
// // };

// // export default MonthCalendar;




// import React, { useState, useEffect, useCallback } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import moment from "moment";
// import Events from "./monthevents";

// const localizer = momentLocalizer(moment);

// const MonthCalendar = () => {
// 	const [eventsData, setEventsData] = useState([]);
// 	const [currentDate, setCurrentDate] = useState(new Date());
// 	const [selectedDate, setSelectedDate] = useState(new Date());
// 	const [selectedView, setSelectedView] = useState("month");
// 	const [reload, setReload] = useState(false);
  
// 	const handleSelect = ({ start }) => {
// 	  setSelectedDate(start);
// 	};
  
// 	const getEventData = async () => {
// 	  try {
// 		setEventsData(Events);
// 	  } catch (error) {
// 		console.error("Error setting events data:", error);
// 	  }
// 	};
  
// 	useEffect(() => {
// 	  getEventData();
// 	}, [currentDate, reload, selectedView]);
  
// 	const dayPropGetter = useCallback(
// 	  (date) => {
// 		const eventDay = moment(date).format("D");
// 		const eventMonth = moment(date).format("M");
  
// 		const matchingEvent = eventsData.find(
// 		  (e) =>
// 			moment(e.start).format("D") === eventDay &&
// 			moment(e.start).format("M") === eventMonth
// 		);
  
// 		if (matchingEvent) {
// 		  const backgroundColor =
// 			matchingEvent.type === "holiday" ? "red" : "#fff";
  
// 		  return {
// 			style: {
// 			  backgroundColor,
// 			  color: "white",
// 			},
// 		  };
// 		}
  
// 		return {};
// 	  },
// 	  [eventsData]
// 	);
  
// 	const eventStyleGetter = useCallback(
// 	  (event) => {
// 		const backgroundColor = event.type === "holiday" ? "red" : "#fff";
  
// 		return {
// 		  style: {
// 			backgroundColor,
// 		  },
// 		};
// 	  },
// 	  []
// 	);
  
// // 	return (
// // 	  <div className="app h-screen month-calendar">
// // 		<div className="calendar-container h-[300px] w-[300px] mx-10 justify-center">
// // 		  <Calendar
// // 			popup
// // 			dayPropGetter={dayPropGetter}
// // 			eventStyleGetter={eventStyleGetter}
// // 			style={{ height: "350px", width: "350px" }}
// // 			allDayMaxRows={1}
// // 			localizer={localizer}
// // 			onNavigate={(date) => setSelectedDate(date)}
// // 			events={eventsData}
// // 			defaultDate={currentDate}
// // 			onSelectSlot={handleSelect}
// // 			titleAccessor={(event) => (
// // 			  <span>
// // 				{event.type === "birthday" ? (
// // 				  <i class="fa-solid fa-cake-candles fa-lg p-0 text-red-400"></i>
// // 				) : event.type === "anniversary" ? (
// // 				  <i className="fa-solid fa-calendar-days fa-lg p-0 text-blue-400">
// // 					{" "}
// // 				  </i>
// // 				) 
// // 				: event.type === "holiday" ? (
// // 					<i class="fa-solid fa-calendar-xmark fa-lg p-0 text-white"></i>)
// // 				 : null}
// // 			  </span>
// // 			)}
// // 			tooltipAccessor={(event) =>
// // 			  event.title && event.description
// // 				? "Title: " + event.title + ", Description: " + event.description
// // 				: "Title: " + event.title
// // 			}
// // 			components={{
// // 			  toolbar: (toolbarProps, onNavigate) => (
// // 				<>
// // 				  <div className="text-gray-600">
// // 					<div className="flex justify-between items-center">
// // 					  <div className="flex items-center">
// // 						<div className="rbc-toolbar mt-[10px] ml-5">
// // 						  <button
// // 							type="button"
// // 							className="bg-blue-600 text-gray-600 font-bold py-1 px-2 mr-2 cursor-pointer"
// // 							title="Today"
// // 							onClick={() => toolbarProps.onNavigate("TODAY")}
// // 						  >
// // 							Today
// // 						  </button>
  
// // 						  <i
// // 							className="fa-solid fa-chevron-left py-1 pl-2 mr-2 cursor-pointer"
// // 							title="Previous"
// // 							onClick={() => toolbarProps.onNavigate("PREV")}
// // 						  ></i>
  
// // 						  <i
// // 							className="fa-solid fa-chevron-right py-1 px-2 cursor-pointer"
// // 							title="Next"
// // 							onClick={() => toolbarProps.onNavigate("NEXT")}
// // 						  ></i>
// // 						</div>
// // 						<div className="rbc-toolbar ml-2">
// // 						  <span className="rbc-toolbar-label text-[14px] mt-[10px]">
// // 							{moment(selectedDate).format("MMMM YYYY")}
// // 						  </span>
// // 						</div>
// // 					  </div>
// // 					</div>
// // 				  </div>
// // 				  <hr className="w-full p-0 m-0" />
// // 				</>
// // 			  ),
// // 			}}
// // 			views={{
// // 			  month: true,
// // 			  timeGrid: {
// // 				eventLimit: 1,
// // 			  },
// // 			}}
// // 			view={selectedView}
// // 		  />

	

// // 		</div>
// // 	  </div>
// // 	);
// //   };
  
// //   export default MonthCalendar;
  
// return (
//     <div className="app h-screen month-calendar">
//       <div className="calendar-container h-[300px] w-[300px] mx-10 justify-center">
//         <Calendar
//           popup
//           dayPropGetter={dayPropGetter}
//           eventStyleGetter={eventStyleGetter}
//           style={{ height: "350px", width: "350px" }}
//           allDayMaxRows={1}
//           localizer={localizer}
//           onNavigate={(date) => setSelectedDate(date)}
//           events={eventsData}
//           defaultDate={currentDate}
//           onSelectSlot={handleSelect}
//           titleAccessor={(event) => (
//             <div className="event-container text-black">
//               {
// 			//   event.type === "birthday" ? (
//             //     <i className="fa-solid fa-cake-candles fa-lg p-0 text-red-400"></i>
//             //   ) 
// 			event.type === "holiday" ? (
//                 <i className="fa-solid fa-calendar-xmark fa-lg p-0 text-white"></i>
//               )
// 			: event.type === "anniversary" ? (
//                 <i className="fa-solid fa-calendar-days fa-lg p-0 text-blue-400"></i>
//               ) : event.type === "birthday" ? (
//                 <i className="fa-solid fa-cake-candles fa-lg p-0 text-red-400"></i>
//               ) 
// 			//    event.type === "holiday" ? (
//             //     <i className="fa-solid fa-calendar-xmark fa-lg p-0 text-white"></i>
//             //   ) 
// 			: null}
//               <span className="event-title">{" "+ event.title}</span>
//             </div>
//           )}
//           tooltipAccessor={(event) =>
//             event.title && event.description
//               ? "Title: " + event.title + ", Description: " + event.description
//               : "Title: " + event.title
//           }
//           components={{
//             toolbar: (toolbarProps, onNavigate) => (
//               <>
//                 <div className="text-gray-600">
//                   <div className="flex justify-between items-center">
//                     <div className="flex items-center">
//                       <div className="rbc-toolbar mt-[10px] ml-5">
//                         <button
//                           type="button"
//                           className="bg-blue-600 text-gray-600 font-bold py-1 px-2 mr-2 cursor-pointer"
//                           title="Today"
//                           onClick={() => toolbarProps.onNavigate("TODAY")}
//                         >
//                           Today
//                         </button>

//                         <i
//                           className="fa-solid fa-chevron-left py-1 pl-2 mr-2 cursor-pointer"
//                           title="Previous"
//                           onClick={() => toolbarProps.onNavigate("PREV")}
//                         ></i>

//                         <i
//                           className="fa-solid fa-chevron-right py-1 px-2 cursor-pointer"
//                           title="Next"
//                           onClick={() => toolbarProps.onNavigate("NEXT")}
//                         ></i>
//                       </div>
//                       <div className="rbc-toolbar ml-2">
//                         <span className="rbc-toolbar-label text-[14px] mt-[10px]">
//                           {moment(selectedDate).format("MMMM YYYY")}
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <hr className="w-full p-0 m-0" />
//               </>
//             ),
//           }}
//           views={{
//             month: true,
//             timeGrid: {
//               eventLimit: 1,
//             },
//           }}
//           view={selectedView}
//         />
//       </div>
//     </div>
//   );
// };

// export default MonthCalendar;


import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Events from "./monthevents";

const localizer = momentLocalizer(moment);

const MonthCalendar = () => {
  const [eventsData, setEventsData] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState("month");
  const [reload, setReload] = useState(false);

  const handleSelect = ({ start }) => {
    setSelectedDate(start);
  };

  const getEventData = async () => {
    try {
      const sortedEvents = Events.sort((a, b) => {
        if (a.type === "holiday" && b.type !== "holiday") return -1;
        if (a.type !== "holiday" && b.type === "holiday") return 1;
        return a.start - b.start;
      });

      setEventsData(sortedEvents);
    } catch (error) {
      console.error("Error setting events data:", error);
    }
  };

  useEffect(() => {
    getEventData();
  }, [currentDate, reload, selectedView]);

  const dayPropGetter = useCallback(
    (date) => {
      const eventDay = moment(date).format("D");
      const eventMonth = moment(date).format("M");

      const matchingEvent = eventsData.find(
        (e) =>
          moment(e.start).format("D") === eventDay &&
          moment(e.start).format("M") === eventMonth
      );

      if (matchingEvent) {
        const backgroundColor =
          matchingEvent.type === "holiday" ? "red" : "#fff";

        return {
          style: {
            backgroundColor,
            color: "white",
          },
        };
      }

      return {};
    },
    [eventsData]
  );

  const eventStyleGetter = useCallback(
    (event) => {
      const backgroundColor = event.type === "holiday" ? "red" : "#fff";

      return {
        style: {
          backgroundColor,
        },
      };
    },
    []
  );

  return (
    <div className="app h-screen month-calendar">
      <div className="calendar-container h-[300px] w-[300px] mx-10 justify-center">
        <Calendar
          popup
          dayPropGetter={dayPropGetter}
          eventStyleGetter={eventStyleGetter}
          style={{ height: "350px", width: "350px" }}
          allDayMaxRows={1}
          localizer={localizer}
          onNavigate={(date) => setSelectedDate(date)}
          events={eventsData}
          defaultDate={currentDate}
          onSelectSlot={handleSelect}
          titleAccessor={(event) => (
            <div className="event-container text-black">
              {event.type === "holiday" ? (
                <i className="fa-solid fa-calendar-xmark fa-lg p-0 "></i>
              ) : event.type === "birthday" ? (
                <i className="fa-solid fa-cake-candles fa-lg p-0 text-red-400"></i>
              ) : event.type === "anniversary" ? (
                <i className="fa-solid fa-calendar-days fa-lg p-0 text-blue-400"></i>
              ) : null}
              <span className="event-title">{" " + event.title}</span>
            </div>
          )}
          tooltipAccessor={(event) =>
            event.title && event.description
              ? "Title: " + event.title + ", Description: " + event.description
              : "Title: " + event.title
          }
          components={{
            toolbar: (toolbarProps, onNavigate) => (
              <>
                <div className="text-gray-600">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="rbc-toolbar mt-[10px] ml-5">
                        <button
                          type="button"
                          className="bg-blue-600 text-gray-600 font-bold py-1 px-2 mr-2 cursor-pointer"
                          title="Today"
                          onClick={() => toolbarProps.onNavigate("TODAY")}
                        >
                          Today
                        </button>

                        <i
                          className="fa-solid fa-chevron-left py-1 pl-2 mr-2 cursor-pointer"
                          title="Previous"
                          onClick={() => toolbarProps.onNavigate("PREV")}
                        ></i>

                        <i
                          className="fa-solid fa-chevron-right py-1 px-2 cursor-pointer"
                          title="Next"
                          onClick={() => toolbarProps.onNavigate("NEXT")}
                        ></i>
                      </div>
                      <div className="rbc-toolbar ml-2">
                        <span className="rbc-toolbar-label text-[14px] mt-[10px]">
                          {moment(selectedDate).format("MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="w-full p-0 m-0" />
              </>
            ),
          }}
          views={{
            month: true,
            timeGrid: {
              eventLimit: 1,
            },
          }}
          view={selectedView}
        />
      </div>
    </div>
  );
};

export default MonthCalendar;

const Events = [
    {
      start: new Date(2024, 1, 18, 0, 0, 0),
      end: new Date(2024, 1, 18, 0, 0, 0),
      title: "Ayushi Agrawal",
      description: "Ayushi Agrawal's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 1, 21, 0, 0, 0),
      end: new Date(2024, 1, 21, 0, 0, 0),
      title: "Aranjeeta Parihar",
      description: "Aranjeeta Parihar's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 1, 21, 0, 0, 0),
      end: new Date(2024, 1, 21, 0, 0, 0),
      title: "Akshat Parihar",
      description: "Akshat Parihar's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 1, 21, 0, 0, 0),
      end: new Date(2024, 1, 21, 0, 0, 0),
      title: "test",
      description: "Test's anniversary",
      type: "anniversary",
    },
    {
      start: new Date(2024, 1, 4, 0, 0, 0),
      end: new Date(2024, 1, 4, 0, 0, 0),
      title: "Arpita Gour",
      description: "Arpita Gour's Work Anniversary",
      type: "anniversary",
    },
    {
      start: new Date(2024, 1, 24, 0, 0, 0),
      end: new Date(2024, 1, 24, 0, 0, 0),
      title: "Yuvraj Chouhan",
      description: "Yuvraj Chouhan's Work Anniversary",
      type: "anniversary",
    },
    {
      start: new Date(2024, 1, 9, 0, 0, 0),
      end: new Date(2024, 1, 9, 0, 0, 0),
      title: "Suyash Tanwar",
      description: "Suyash Tanwar's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 3, 24, 0, 0, 0),
      end: new Date(2024, 3, 24, 0, 0, 0),
      title: "Nitesh Vishwakarma",
      description: "Nitesh Vishwakarma's Work Anniversary",
      type: "anniversary",
    },
    {
      start: new Date(2024, 3, 12, 0, 0, 0),
      end: new Date(2024, 3, 12, 0, 0, 0),
      title: "Dimple Ahuja",
      description: "Dimple Ahuja's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 3, 12, 0, 0, 0),
      end: new Date(2024, 3, 12, 0, 0, 0),
      title: "Sakshi Jain",
      description: "Sakshi Jain's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 3, 12, 0, 0, 0),
      end: new Date(2024, 3, 12, 0, 0, 0),
      title: "Sakshi Jain",
      description: "Sakshi Jain's anniversary",
      type: "anniversary",
    }
    ,{
      start: new Date(2024, 2, 12, 0, 0, 0),
      end: new Date(2024, 2, 12, 0, 0, 0),
      title: "Naina Ahuja",
      description: "Naina Ahuja's birthday",
      type: "birthday",
    },
    {
      start: new Date(2024, 0, 26, 0, 0, 0),
      end: new Date(2024, 0, 26, 0, 0, 0),
      title: "Republic Day",
      description: "It's Republic Day Holiday",
      type: "holiday",
    },
    {
      start: new Date(2024, 2, 23, 0, 0, 0),
      end: new Date(2024, 2, 23, 0, 0, 0),
      title: "Day off",
      description: "It's Day off",
      type: "holiday",
    },
    {
      start: new Date(2024, 2, 12, 0, 0, 0),
      end: new Date(2024, 2, 12, 0, 0, 0),
      title: "Extra Holiday",
      description: "It's a Holiday",
      type: "holiday",
    },
    
    {
      start: new Date(2024, 1, 19, 0, 0, 0),
      end: new Date(2024, 1, 19, 0, 0, 0),
      title: "Holiday",
      description: "Today is a holiday",
      type: "holiday",
    },
    {
      start: new Date(2024, 1, 21, 0, 0, 0),
      end: new Date(2024, 1, 21, 0, 0, 0),
      title: "Holiday",
      description: "Today is a holiday",
      type: "holiday",
    },
  ];
  
  export default Events;
  
import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	useMemo,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Year from "./Year";
import Header from "../Header";
import AddEventModal from "../Events/createEvent";
import { getEvent } from "../Events/getEventList";
import useClickOutside from "./clickOutside";
import EditEventModal from "../Events/editEvent";

const localizer = momentLocalizer(moment);

const Index = () => {
	const [selectedView, setSelectedView] = useState(
		localStorage.getItem("selectedView") || "month"
	);
	const [eventsData, setEventsData] = useState([]);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [reload, setReload] = useState(false);
	const [start, setStart] = useState(new Date());
	const [end, setEnd] = useState(new Date());
	// const [coords, setCoords] = useState({ x: 0, y: 0 });
	const headerRef = useRef();
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
	const [isShowEventModalOpen, setIsShowEventModalOpen] = useState(false);
	const [eventDetails, setEventDetails] = useState(null);
	const options = [
		{ value: "day", label: "Day" },
		{ value: "week", label: "Week" },
		{ value: "month", label: "Month" },
		{ value: "year", label: "Year" },
	];

	const handleDateSelect = (date) => {
		setSelectedDate(date);
		setSelectedView("day");
		setCurrentDate(date);
	};

	// useClickOutside(headerRef, () => {
	// 	setCoords({
	// 		x: headerRef.current.getBoundingClientRect().left,
	// 		y: headerRef.current.getBoundingClientRect().bottom,
	// 	});
	// });

	const handleViewChange = (selectedOption) => {
		setSelectedView(selectedOption.value);
		localStorage.setItem("selectedView", selectedOption.value);
		if (selectedOption.value === "day") {
			setSelectedDate(new Date());
		}
	};

	const handleNavigate = (view, date) => {
		setSelectedView(view);
		setCurrentDate(date);
	};

	const handleSelect = ({ start, end }) => {
		setIsCreateEventModalOpen(true);
		setIsShowEventModalOpen(false);
		setSelectedDate(start);
		const formattedStart = moment(start).format("YYYY-MM-DDTHH:mm");
		const formattedEnd = moment(end).format("YYYY-MM-DDTHH:mm");
		setStart(formattedStart);
		setEnd(formattedEnd);
	};

	const showEvent = (event) => {
		setIsShowEventModalOpen(true);
		setIsCreateEventModalOpen(false);
		setEventDetails(event);
	};

	const getEventData = async () => {
		try {
			const events = await getEvent();
			setEventsData(events);
			setReload(false);
		} catch (error) {
			console.error("Error setting events data:", error);
		}
	};

	useEffect(() => {
		getEventData();
	}, [currentDate, reload, selectedView]);

	const dayPropGetter = useCallback(
		(date) => {
			const isSelectedDate = moment(date).isSame(selectedDate, "day");
			const isToday = moment(date).isSame(new Date(), "day");
			const isModalOpen = isCreateEventModalOpen;

			return {
				style: {
					backgroundColor:
						(isSelectedDate || isToday) && isModalOpen
							? "#E1F6FF"
							: isToday
							? "#E1F6FF"
							: "white",
					color:
						(isSelectedDate || isToday) && isModalOpen
							? "white"
							: "black",
				},
			};
		},
		[selectedDate, isCreateEventModalOpen]
	);

	const eventPropGetter = useCallback(
		(event, start, end, isSelected) => {
			const isModalOpen = isShowEventModalOpen;
			return {
				...(isSelected && isModalOpen
					? {
							style: {
								backgroundColor: "green",
							},
					  }
					: {
							style: {
								backgroundColor: "#3174ad",
							},
					  }),
			};
		},
		[isShowEventModalOpen]
	);

	const formats = useMemo(
		() => ({
			eventTimeRangeFormat: () => {
				return "";
			},
		}),
		[]
	);

	return (
		<div className="app h-screen">
			<div className="calendar-container h-full w-[95%] mx-10 justify-center">
				{/* <div className="calendar-container h-full w-full mx-2 sm:w-[95%] md:w-[75%] lg:w-[50%] xl:w-[35%]"> */}
				<Calendar
					selectable
					popup
					formats={formats}
					localizer={localizer}
					dayPropGetter={dayPropGetter}
					eventPropGetter={eventPropGetter}
					onNavigate={(date) => setSelectedDate(date)}
					events={eventsData}
					defaultDate={currentDate}
					onSelectEvent={(event) => showEvent(event)}
					onSelectSlot={handleSelect}
					tooltipAccessor={(event) =>
						event.title && event.description
							? "Title: " +
							  event.title +
							  ", Description: " +
							  event.description
							: "Title: " + event.title
					}
					components={{
						toolbar: (toolbarProps) => (
							<div>
								<Header
									onCalendarViewChange={handleViewChange}
									calendarViewOptions={options}
									onNavigate={handleNavigate}
									onDateSelect={handleDateSelect}
									selectedDate={selectedDate}
									selectedView={selectedView}
									reload={() => setReload(true)}
									{...toolbarProps}
								/>
							</div>
						),
					}}
					views={{
						day: true,
						week: true,
						month: true,
						year: Year,
					}}
					view={selectedView}
					messages={{ year: "Year" }}
				/>
				{isShowEventModalOpen && (
					<EditEventModal
						isOpen={isShowEventModalOpen}
						onClose={() => setIsShowEventModalOpen(false)}
						eventDetails={eventsData}
						reload={() => setReload(true)}
						e_Id={eventDetails.id}
					/>
				)}
				{isCreateEventModalOpen && (
					<AddEventModal
						isOpen={isCreateEventModalOpen}
						getEvent={getEvent}
						onClose={() => {
							setIsCreateEventModalOpen(false);
							setIsAddModalOpen(false);
						}}
						reload={() => setReload(true)}
						onSave={() => {
							setIsCreateEventModalOpen(false);
							setIsAddModalOpen(false);
						}}
						start={start}
						end={start}
					/>
				)}
			</div>
		</div>
	);
};

export default Index;

import axios from "axios";

export const DeleteEvent = async (id, onClose, reload) => {
  try {
    await axios.post(process.env.REACT_APP_API_URL + "events/delete", { id });
    onClose();
    reload();
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};



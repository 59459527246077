import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import useClickOutside from "../calendar/clickOutside";
import EventDetailModal from "./detailPopup";

const ShowEventModal = ({
	isOpen,
	onClose,
	onEdit,
	onDelete,
	eventDetails,
	coords,
	reload,
	selectedDate,
}) => {
	const [position, setPosition] = useState({ top: 0, left: 0 });
	const modalRef = useRef();
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [isShow, setIsShow] = useState(false);
	const [positionStyle, setPositionStyle] = useState({});
	 const popupRef = useRef(null);
	
	  // Calculate position dynamically
	  const calculatePosition = () => {
		if (popupRef.current) {
		  const popupRect = popupRef.current.getBoundingClientRect();
		  const viewportHeight = window.innerHeight;
		  const viewportWidth = window.innerWidth;

		  const newPositionStyle = {};
	
		  if (popupRect.bottom > viewportHeight) {
				newPositionStyle.bottom = '0px';
		  } else {
			newPositionStyle.top = `${popupRect.top}px`;
		  }
	
		  if (popupRect.right > viewportWidth) {
			newPositionStyle.right = '0px';
		  } else {
			newPositionStyle.left = `${popupRect.left}px`;
		  }
	
		  setPositionStyle(newPositionStyle);
		}
	  };
	
	  useEffect(() => {
		calculatePosition();
		window.addEventListener('resize', calculatePosition);
		return () => {
		  window.removeEventListener('resize', calculatePosition);
		};
	  }, []);
	
	 
	const handleEventClick = (event) => {
		setDetailsModalOpen(true);
		setSelectedEvent(event);
	};

	let day;
	let date;
	day = moment(selectedDate).format("ddd");
	date = moment(selectedDate).format("DD");

	useClickOutside(modalRef, onClose);

	useEffect(() => {
		if (coords) {
			const { x, y } = coords;
			setPosition({ top: y, left: x });
		}
	}, [coords]);

	useEffect(() => {
		setPosition((prevPosition) => ({
			...prevPosition,
			selectedDate,
		}));
	}, [selectedDate]);

	useEffect(() => {
        if(isOpen==true){
            setTimeout(function(){
                setIsShow(true)
            },200);
        }
        else
        {
            setIsShow(false)
        }
    }, [isOpen]);

	return (
		<div ref={popupRef}
		className="popup"
		style={{ ...positionStyle }}>
			{isOpen && eventDetails && eventDetails.length > 0 && (
				<div
					ref={modalRef}
					className={`w-[270px] fixed bg-white p-5 pt-3 z-50 shadow-xl`}
					style={{
						top: `${position.top + 20}px`,
						left: `${position.left + 20}px`,
						border: "1px solid #ccc",
						display:isShow==true?"block":"none"
					}}
				>
					<div className="pt-0"></div>
					<div className="flex justify-end">
						<button
							className="text-gray-500 pt-3"
							onClick={onClose}
						>
							<i
								className="fa-solid fa-circle-xmark fa-2xl cursor-pointer text-[#c0c1c4]"
								title="Close"
							></i>
						</button>
					</div>
					<div className="grid grid-cols-1 gap-4 text-center text-gray-500">
						{day}
					</div>
					<div className="grid grid-cols-1 gap-4 text-center text-[28px]">
						{date}
					</div>
					{eventDetails.map((event, index) => (
						<div
							key={index}
							type="popup"
							tabIndex="0"
							className="rbc-event mb-[10px]"
							onClick={() => handleEventClick(event)}
							style={{maxHeight: "100px", // Set the maximum height as per your requirement
					overflowY: "auto"}}
						>
							<div className="rbc-event-content">
								{event.title}
							</div>
						</div>
					))}
					<EventDetailModal
						isOpen={detailsModalOpen}
						onClose={() => {setDetailsModalOpen(false)
						onClose();
						}}
						onEdit={onEdit}
						onDelete={onDelete}
						eventDetails={selectedEvent}
						coords={coords}
						selectedDate={selectedDate}
						reload={reload}
					/>
				</div>
			)}

			{isOpen && (!eventDetails || eventDetails.length === 0) && (
				<div
					ref={modalRef}
					className={`w-[270px] absolute bg-white p-5 pt-0 z-50 shadow-xl`}
					style={{
						top: `${position.top + 20}px`,
						left: `${position.left + 20}px`,
						border: "1px solid #ccc",
						display:isShow==true?"block":"none"
					}}
				>
					<div className="flex justify-end">
						<button
							className="text-gray-500 pt-3"
							onClick={onClose}
						>
							<i
								className="fa-solid fa-circle-xmark fa-2xl cursor-pointer text-[#c0c1c4]"
								title="Close"
							></i>
						</button>
					</div>
					<div className="grid grid-cols-1 gap-4 text-center text-gray-500">
						{day}
					</div>
					<div className="grid grid-cols-1 gap-4 text-center text-[28px]">
						{date}
					</div>
					<div className="grid grid-cols-1 gap-4 mt-4 text-gray-600">
						There are no events scheduled on this day.
					</div>
				</div>
			)}
		</div>
	);
};

export default ShowEventModal;


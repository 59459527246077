import React, { useState } from "react";
import Select from "react-select";
import AddEventModal from "../Events/createEvent";
import moment from "moment";

const Header = ({
	onCalendarViewChange,
	calendarViewOptions,
	selectedView,
	onNavigate,
	selectedDate,
	reload,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	let formattedMonthAndYear;

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	if (selectedView === "day") {
		formattedMonthAndYear = moment(selectedDate).format("MMMM DD, YYYY");
	} else if (selectedView === "week") {
		const startOfWeek = moment(selectedDate)
			.startOf("week")
			.format("MMMM DD, YYYY");
		const endOfWeek = moment(selectedDate)
			.endOf("week")
			.format("MMMM DD, YYYY");
		formattedMonthAndYear = `${startOfWeek} - ${endOfWeek}`;
	} else if (selectedView === "year") {
		formattedMonthAndYear = moment(selectedDate).format("YYYY");
	} else {
		formattedMonthAndYear = moment(selectedDate).format("MMMM YYYY");
	}

	return (
		<>
			<div className="p-4 text-gray-600">
				<div className="flex justify-between items-center">
					<div className="flex items-center">
						<div className="rbc-toolbar mt-[10px] flex items-center ml-5">
							<div className="font-bold text-[22px] mr-10">
								<i class="fa-solid fa-calendar-days"></i>{" "}
								Calendar
							</div>
							<button
								type="button"
								className="bg-blue-600 text-gray-600 font-bold py-2 px-4 mr-4 cursor-pointer"
								title="Today"
								onClick={() => onNavigate("TODAY")}
							>
								Today
							</button>

							<i
								className="fa-solid fa-chevron-left py-2 pl-4 mr-4 cursor-pointer"
								title="Previous"
								onClick={() => onNavigate("PREV")}
							></i>

							<i
								className="fa-solid fa-chevron-right py-2 px-4 cursor-pointer"
								title="Next"
								onClick={() => onNavigate("NEXT")}
							></i>
						</div>
						<div className="rbc-toolbar ml-8">
							<span className="rbc-toolbar-label text-[22px] mt-[10px]">
								{formattedMonthAndYear}
							</span>
						</div>
					</div>
					<div className="flex items-center">
						<Select
							options={calendarViewOptions}
							isSearchable={false}
							value={calendarViewOptions.find(
								(option) => option.value === selectedView
							)}
							onChange={onCalendarViewChange}
							className="ml-4 mr-4 z-50 cursor-pointer"
						/>
						<button
							className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl"
							onClick={toggleModal}
						>
							<i className="fa-solid fa-plus fa-xl cursor-pointer"></i>{" "}
							<span className="pl-3 pr-7"> Create</span>
						</button>
					</div>
				</div>
			</div>
			{isModalOpen && (
				<AddEventModal
					isOpen={isModalOpen}
					onClose={() => {
						setIsModalOpen(false);
						reload();
					}}
					reload={reload}
					onSave={(title, description, start, end) => {
						console.log("reload details:", reload);
						reload();
					}}
					start={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
					end={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
				/>
			)}
			<hr className="w-full p-0 m-0"/>
		</>
	);
};

export default Header;

import React, { useState } from "react";
import {
	Container,
	Row,
	Col,
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import moment from "moment";
import AddEventModal from "../Events/createEvent";
import { useRef } from "react";
import Header from "../Header";
import { Calendar, momentLocalizer } from "react-big-calendar";
const localizer = momentLocalizer(moment);

const Sidebar = ({ onNavigate, reload, onDateSelect }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [sidebarDate, setSidebarDate] = useState(new Date());
	const headerRef = useRef();
	const options = [
		{ value: "day", label: "Day" },
		{ value: "month", label: "Month" },
	];

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		reload();
	};

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = ({ start }) => {
		setSidebarDate(start);
		onNavigate("day", start);
	};

	const dayPropGetter = (date) => {
		const isToday = moment(date).isSame(new Date(), "day");

		return {
			className: isToday ? "today-highlight" : "",
		};
	};

	return (
		<Container fluid>
			<Row>
				<Col md={2} className={`sidebar ${isOpen ? "open" : "closed"}`}>
					<Navbar color="light" light expand="md">
						<NavbarToggler onClick={toggleSidebar} />
					</Navbar>
					<Collapse isOpen={isOpen}>
						<Nav vertical className="pt-0 p-4 text-gray-600">
							<NavItem className="mb-3">
								<div className="font-bold text-[22px]">
									<i class="fa-solid fa-calendar-days"></i>{" "}
									Calendar
								</div>
								{/* <hr/> */}
							</NavItem>
							<NavItem className="mb-3">
								<button
									// className="bg-blue-600 text-white font-bold py-2 px-4 mr-4"
									className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl"
									onClick={() => {
										toggleModal();
									}}
								>
									<i className="fa-solid fa-plus fa-xl cursor-pointer "></i>
									{"  "}
									{/* Add Event */}
									<span className="pl-3 pr-7"> Create</span>
								</button>
							</NavItem>
							<NavItem className="h-[250px] w-full pt-5 p-3">
								{/* <Calendar
									onChange={(date) => setSidebarDate(date)}
									value={sidebarDate}
									dayPropGetter={dayPropGetter}
									selectable
									localizer={localizer}
									events={[]}
									defaultDate={sidebarDate}
									onSelectEvent={() => {}}
									onNavigate={(date, view) =>
										onNavigate(view, date)
									}
									onSelectSlot={handleSelect}
									views={["month"]}
									components={{
										toolbar: (props) => (
											<div className="rbc-toolbar flex text-sm justify-between">
											<div className="mr-2">
													<span className="text-sm font-semibold">
														{moment(
															props.label
														).format("MMMM YYYY")}
													</span>
												</div>
												<div className="justify-between">
													<i
														className="fa-solid fa-chevron-left py-2 pl-2 pr-0 text-gray-700 flex-end cursor-pointer"
														onClick={() =>
															props.onNavigate(
																"PREV"
															)
														}
													></i>
													<i
														className="fa-solid fa-chevron-right py-2 px-4 text-gray-700 cursor-pointer"
														onClick={() =>
															props.onNavigate(
																"NEXT"
															)
														}
													></i>
												</div>
											</div>
										),
									}}
								/> */}
							</NavItem>
						</Nav>
						{isModalOpen && (
							<AddEventModal
								isOpen={isModalOpen}
								onClose={() => {
									setIsModalOpen(false);
									reload();
								}}
								reload={reload}
								onSave={(title, description, start, end) => {
									console.log("reload details:", reload);
								}}
								start={moment(new Date()).format(
									"YYYY-MM-DDTHH:mm"
								)}
								end={moment(new Date()).format(
									"YYYY-MM-DDTHH:mm"
								)}
							/>
						)}
					</Collapse>
				</Col>
			</Row>
		</Container>
	);
};

export default Sidebar;

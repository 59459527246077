import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { getEvent } from "./getEventList";
import moment from "moment";
import { DeleteEvent } from "./deleteEvent";
import useClickOutside from "../calendar/clickOutside";

const EditEventModal = ({ isOpen, onClose, eventDetails, reload, e_Id }) => {
	console.log("edit id", e_Id);
	const [event, setEvent] = useState({
		title: "",
		start: null,
		end: null,
		description: "",
		id: "",
	  });
	  const modalRef = useRef();
	  const [isInvalidDate, setIsInvalidDate] = useState(false);
	
	  useEffect(() => {
		getEditDetails();
	  }, []);
	
	//   useEffect(() => {
	// 	if (isOpen) {
	// 	  document.body.style.overflow = "hidden";
	// 	} else {
	// 	  document.body.style.overflow = "auto";
	// 	}
	//   }, [isOpen]);

	  useEffect(() => {
		const isInvalid =
		  moment(event.end).isBefore(event.start, "minute") ||
		  (event.start && event.end && moment(event.end).isBefore(event.start, "day"));
	
		setIsInvalidDate(isInvalid);
	  }, [event.start, event.end]);
	
	  const getEditDetails = async () => {
		try {
		  const response = await axios.post(
			process.env.REACT_APP_API_URL + "events/edit",
			{
			  id: e_Id,
			}
		  );
	
		  const eventData = response.data.data[0];
		  console.log("Response:", eventData);
	
		  setEvent({
			title: eventData.title || "",
			description: eventData.description || "",
			start: eventData.start ? new Date(eventData.start) : null,
			end: eventData.end ? new Date(eventData.end) : null,
			id: e_Id || "",
		  });
		} catch (error) {
		  console.error("Error:", error);
		}
	  };
	  
	  const handleChange = (e) => {
		const { name, value } = e.target;
	
		setEvent((prevEvent) => ({
		  ...prevEvent,
		  [name]: value,
		}));
	  };
	
	const handleUpdate = () => {
		if (moment(event.end).isBefore(event.start, "minute")) {
			setIsInvalidDate(true);
			return;
		}
	
		const updatedEvent = {
			title: event.title,
			description: event.description,
			start: moment(event.start).format("YYYY-MM-DDTHH:mm:ss"),
			end: moment(event.end).format("YYYY-MM-DDTHH:mm:ss"),
			id: event.id,
		};
	
		axios
			.post(process.env.REACT_APP_API_URL + "events/update", updatedEvent)
			.then((response) => {
				getEvent().then((events) => {});
				reload(); 
				onClose();
			})
			.catch((error) => {
				console.log("error", error);
			});
	};
	 
	
	const handleDelete = () => {
		DeleteEvent(e_Id, onClose, reload);
	  };
	
	  useClickOutside(modalRef, onClose);
	
	  return (
		<>
		  {isOpen && (
			<div
			  ref={modalRef}
			  className="fixed inset-0 overflow-y-auto z-10"
			  style={{ border: "1px solid #ccc" }}
			>
			  <div className="flex items-center justify-center min-h-screen">
				<div className="bg-white shadow-lg p-6 rounded-md max-w-md w-full">
				  <div className="flex justify-end text-gray-400">
					<i
					  className="fa-solid fa-trash py-2 px-2 mr-1"
					  onClick={handleDelete}
					></i>
					<i
					  className="fa-solid fa-circle-xmark fa-2xl mt-4"
					  onClick={onClose}
					></i>
				  </div>
				  <div className="p-4">
					<div className="grid grid-cols-1 gap-4 mt-4">
					  <div>
						<input
						  type="text"
						  id="title"
						  name="title"
						  className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
						  placeholder="Add title"
						  value={event.title}
						  onChange={handleChange}
						/>
					  </div>
					  <div>
						<input
						  type="text"
						  rows="2"
						  name="description"
						  placeholder="Add a description"
						  value={event.description}
						  className="border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
						  onChange={handleChange}
						/>
					  </div>
					  <div className="grid grid-cols-2 gap-4">
						<input
						  type="datetime-local"
						  name="start"
						  placeholder="Add Start Date"
						  value={moment(event.start).format("YYYY-MM-DDTHH:mm")}
						  className="border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
						  onChange={handleChange}
						/>
	
						<input
						  type="datetime-local"
						  name="end"
						  placeholder="Add End Date"
						  value={moment(event.end).format("YYYY-MM-DDTHH:mm")}
						  onChange={handleChange}
						  className={`border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500 ${
							isInvalidDate ? "border-red-500" : ""
						  }`}
						/>
					  </div>
					</div>
					<div className="mt-4 flex justify-end">
					  <button
						className={`bg-blue-500 text-white py-2 px-4 ${
						  isInvalidDate
							? "cursor-not-allowed opacity-50"
							: ""
						}`}
						onClick={handleUpdate}
						disabled={isInvalidDate}
					  >
						Update
					  </button>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  )}
		</>
	  );
	};
	
	export default EditEventModal;
	

	
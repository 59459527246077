import axios from "axios";

export const getEvent = async () => {
	try {
		const response = await axios.post(process.env.REACT_APP_API_URL +"events/");
		const formattedEvents = response.data.data.map((event) => ({
			start: new Date(event.start),
			end: new Date(event.end),
			title: event.title,
			description: event.description,
			id: event._id
		}));
		return formattedEvents;
	} catch (error) {
		console.error("Error fetching events:", error);
		throw error;
	}
};

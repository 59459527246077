import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar/sidebar";
import Index from "./components/calendar"
import Header from "./components/Header";
import "./assets/css/style.css"
import "./assets/css/newStyle.css"  
import MonthCalendar from "./components/calendar/monthCalendar";

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Index />} />
				<Route path="/sidebar" element={<Sidebar />} />
				<Route path="/header" element={<Header />} />
				<Route path="/month-days" element={<MonthCalendar />} />
			</Routes>
		</BrowserRouter>
	);
}

import React, { useState, useRef } from "react";
import axios from "axios";
import { getEvent } from "./getEventList";
import useClickOutside from "../calendar/clickOutside";
import moment from "moment";

const AddEventModal = ({
	isOpen,
	onClose,
	onSave,
	reload,
	start,
	end,
}) => {
	const [title, setTitle] = useState("");
	const [description, setDesc] = useState("");
	const [startDate, setStartDate] = useState(start || new Date());
	const [endDate, setEndDate] = useState(end || new Date());
	const [isInvalidDate, setIsInvalidDate] = useState(
		moment(endDate).isBefore(startDate, "minute") || !endDate
	  ); 
	const modalRef = useRef();

	const saveEvent = () => {
		if (moment(endDate).isBefore(startDate, "minute")) {
			setIsInvalidDate(true);
			return;
		}
		const newEvent = {
			title,
			description,
			start: startDate,
			end: endDate,
		};
		axios
			.post(process.env.REACT_APP_API_URL + "events/create", newEvent)
			.then((response) => {
				getEvent().then((events) => {
					console.log(events);
				});
				reload();
			})
			.catch((error) => {
				console.log("error", error);
			});

		onSave(title, description, startDate, endDate);
		onClose();
	};
	useClickOutside(modalRef, onClose);

	return (
		<>
			{isOpen && (
				<div
					ref={modalRef}
					className="h-screen w-full fixed left-0 top-0 flex justify-center items-center z-50"
				>
					<form className="bg-white rounded-lg shadow-2xl w-1/4">
						<header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
							<span className="material-icons-outlined text-gray-400"></span>
							<div>
								<div className="flex justify-end">
									<button
										className="text-gray-500"
										onClick={onClose}
									>
										<i
											className="fa-solid fa-times fa-lg cursor-pointer"
											title="Close"
										></i>
									</button>
								</div>
							</div>
						</header>
						<div className="p-3">
							<div className="grid grid-cols-1/5 items-end gap-y-7">
								<input
									type="text"
									id="title"
									placeholder="Add title"
									value={title}
									className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
									onChange={(e) => setTitle(e.target.value)}
								/>
								<input
									type="text"
									rows="2"
									name="description"
									placeholder="Add a description"
									value={description}
									className="border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
									onChange={(e) => setDesc(e.target.value)}
								/>
								<span className="text-gray-400 font-bold">
									Start Date
								</span>
								<div className="flex items-center">
									<i className="fa-regular fa-clock pr-2"></i>
									<input
										type="datetime-local"
										rows="2"
										name="start date"
										placeholder="Add Start Date"
										value={startDate}
										className="border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
										onChange={(e) =>
											setStartDate(e.target.value)
										}
									/>
								</div>
								<label className="text-gray-400 font-bold">
									End Date
								</label>
								<div className="flex items-center">
									<i className="fa-regular fa-clock pr-2"></i>
									<input
										type="datetime-local"
										rows="2"
										name="end date"
										placeholder="Add End Date"
										value={endDate}
										className={`pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500 ${
											isInvalidDate ? "border-red-500" : ""
										  }`}
										  onChange={(e) => {
											setEndDate(e.target.value);
											setIsInvalidDate(moment(e.target.value).isBefore(startDate, "minute") || !e.target.value);
										  }}
									/>
								</div>
							</div>
						</div>
						<footer className="flex justify-end border-t p-3 mt-5">
							<button
								type="submit"
								onClick={saveEvent}
								className={`bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white ${
									isInvalidDate ? "cursor-not-allowed opacity-50" : ""
								  }`}
								  disabled={isInvalidDate}
							>
								Save
							</button>
						</footer>
					</form>
				</div>
			)}
		</>
	);
};

export default AddEventModal;

import React, { useEffect, useState } from "react";
import moment from "moment";
import * as dates from "date-arithmetic";
import { navigate } from "react-big-calendar/lib/utils/constants";
import ShowEventModal from "../Events/showEvent";
import { getEvent } from "../Events/getEventList";
import { DeleteEvent } from "../Events/deleteEvent";

function createCalendar(currentDate) {
	if (!currentDate) {
		currentDate = moment();
	} else {
		currentDate = moment(currentDate);
	}

	const first = currentDate.clone().startOf("month");
	const last = currentDate.clone().endOf("month");
	const weeksCount = Math.ceil((first.day() + last.date()) / 7);
	const calendar = Object.assign([], { currentDate, first, last });

	for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
		const week = [];
		calendar.push(week);
		calendar.year = currentDate.year();
		calendar.month = currentDate.month();

		for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
			const date = currentDate.clone().set("date", day + 1 - first.day());
			date.calendar = calendar;
			week.push(date);
		}
	}

	return calendar;
}

function CalendarDate(props) {
	const { dateToRender, dateOfMonth, onShowModal, events } = props;
	const today =
		dateToRender.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
			? "today"
			: "";

	if (dateToRender.month() < dateOfMonth.month()) {
		return (
			<button
				disabled={true}
				className="h-[30px] w-[30px] text-[14px] rounded-[50px] bg-white border-none outline-none text-gray-500"
			>
				{dateToRender.date()}
			</button>
		);
	}

	if (dateToRender.month() > dateOfMonth.month()) {
		return (
			<button
				disabled={true}
				className="h-[30px] w-[30px] text-[14px] rounded-[50px] bg-white border-none outline-none text-gray-500"
			>
				{dateToRender.date()}
			</button>
		);
	}

	const eventForDay = events.find((event) =>
		moment(event.start).isSame(dateToRender, "day")
	);

	return (
		<button
			className={`h-[30px] w-[30px] text-[14px] rounded-[50px] text-gray-600 border-none outline-none hover:bg-blue-500 hover:cursor-pointer  ${
				today ? "bg-blue-500 font-bold" : ""
			}`}
			onClick={(e) => onShowModal(dateToRender, e)}
		>
			{dateToRender.date()}
		</button>
	);
}

const MyCalendar = ({ date }) => {
	const [calendar, setCalendar] = useState(null);
	const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isEventModalOpen, setIsEventModalOpen] = useState(false);
	const [eventDetails, setEventDetails] = useState(null);
	const [eventsData, setEventsData] = useState([]);
	const [selectedDate, setSelectedDate] = useState(null);
	const [coords, setCoords] = useState({ x: 0, y: 0 });
	const [reload, setReload] = useState(false);

	const getEventData = async () => {
		try {
			const events = await getEvent();
			setEventsData(events);
			setReload(false);
		} catch (error) {
			console.error("Error setting events data:", error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			await getEventData();
			setReload(false); 
		};
		fetchData();
	}, [reload]);

	const handleSelect = (dateToRender, e) => {
		e.preventDefault();
		setCoords({
			x: e.clientX,
			y: e.clientY,
		});
		setIsCreateEventModalOpen(true);
		const selectedEvents = eventsData.filter((event) => {
			const eventStart = moment(event.start).startOf("day");
			const eventEnd = moment(event.end).endOf("day");
			return dateToRender.isBetween(eventStart, eventEnd, null, "[]");
		});

		setEventDetails(selectedEvents);
		setSelectedDate(dateToRender);
	};
	
	useEffect(() => {
		setCalendar(createCalendar(date));
	}, [date]);

	useEffect(() => {
		getEventData();
	}, [reload]);

	if (!calendar) {
		return null;
	}
	return (
		<div className="m-[5px] mb-[15px]">
			<div className="font-medium text-gray-600">
				{calendar.currentDate.format("MMMM").charAt(0).toUpperCase() +
					calendar.currentDate.format("MMMM").slice(1)}
			</div>
			<div className="flex flex-wrap gap-2 justify-around">
				{["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
					<span
						key={index}
						className="inline-block w-[30px] text-[14px] h-[30px] text-gray-400 text-center leading-[30px]"
					>
						{day}
					</span>
				))}
			</div>
			{calendar.map((week, index) => (
				<div
					key={index}
					className="flex flex-wrap gap-2 justify-around"
				>
					{week.map((date) => (
						<CalendarDate
							key={date.date()}
							events={eventsData}
							dateToRender={date}
							dateOfMonth={calendar.currentDate}
							onShowModal={(event, e) => handleSelect(event, e)}
						/>
					))}
				</div>
			))}

			{isCreateEventModalOpen && eventsData.length > 0 && (
				<ShowEventModal
					isOpen={isCreateEventModalOpen}
					onClose={() => setIsCreateEventModalOpen(false)}
					eventDetails={eventDetails}
					coords={coords}
					reload={() => setReload(true)}
					selectedDate={selectedDate}
				/>
			)}
		</div>
	);
};

const Year = ({ date, onDateClick, reload }) => {
	const range = Year.range(date);
	const months = [];
	const firstMonth = dates.startOf(date, "year");

	for (let i = 0; i < 12; i += 4) {
		const rowMonths = [];
		for (let j = i; j < i + 4; j++) {
			if (j < 12) {
				rowMonths.push(
					<div key={j + 1} className="grow">
						<MyCalendar
							date={dates.add(firstMonth, j, "month")}
							onDateClick={onDateClick}
							reload={reload}
						/>
					</div>
				);
			}
		}
		months.push(
			<div key={i / 4} className="flex flex-wrap gap-10 w-full">
				{rowMonths.map((month) => month)}
			</div>
		);
	}

	return (
		<div className="flex flex-wrap gap-6 justify-between pl-5 pr-5 mt-10">
			{months}
		</div>
	);
};

Year.range = (date) => [dates.startOf(date, "year")];

Year.navigate = (date, action) => {
	switch (action) {
		case navigate.PREVIOUS:
			return dates.add(date, -1, "year");

		case navigate.NEXT:
			return dates.add(date, 1, "year");

		default:
			return date;
	}
};

Year.title = (date, { localizer }) =>
	localizer.format(date, "yearHeaderFormat");

export default Year;

import React, { useEffect, useState, useRef } from "react";
import EditEventModal from "./editEvent";
import moment from "moment";
import useClickOutside from "../calendar/clickOutside";
import { DeleteEvent } from "./deleteEvent";

const EventDetailModal = ({
	isOpen,
	onClose,
	eventDetails,
	coords,
	reload,
	selectedDate,
}) => {
	const [position, setPosition] = useState({ top: 0, left: 0 });
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const modalRef = useRef();
	const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

	useClickOutside(modalRef, onClose, isOpen);

	const handleDelete = async () => {
		try {
			const eventId = eventDetails.id;
			await DeleteEvent(eventId, onClose, reload);
			onClose();
			reload();
		} catch (error) {
			console.error("Error handling delete:", error);
		}
	};

  const handleEdit = () => {
		setIsEditModalOpen(true);
	};

	let day;
	let date;
	let fullDate;
	day = moment(selectedDate).format("ddd");
	date = moment(selectedDate).format("DD");
	fullDate = moment(selectedDate).format("dddd, MMMM DD");

	useEffect(() => {
		if (coords) {
			const { x, y } = coords;
			setPosition({ top: y, left: x });
		}
	}, [coords]);


	useEffect(() => {
		console.log("kk", eventDetails);
		if (isDetailModalOpen && isOpen) {
			onClose();
		}
	}, [isDetailModalOpen, isOpen, onClose]);

	return (
		<div>
			{isOpen && eventDetails ? (
				<div
					ref={modalRef}
					className={`w-[270px] fixed bg-white p-5 pt-3 z-50 shadow-xl`}
					style={{
						top: `${position.top + 20}px`,
						left: `${position.left + 20}px`,
						border: "1px solid #ccc",
						// maxHeight: "300px", // Set the maximum height as per your requirement
						// overflowY: "auto",
					}}
				>
					<div className="flex justify-end text-gray-400">
						<i
							className="fa-solid fa-trash py-2 px-2 mr-1 cursor-pointer"
							onClick={handleDelete}
							title="Delete"
						></i>
						<i
							class="fa-solid fa-pen py-2 px-4 cursor-pointer"
							onClick={handleEdit}
							title="Edit"
						></i>
						<i
							class="fa-solid fa-circle-xmark fa-2xl mt-4 cursor-pointer"
							onClick={onClose}
							title="Close"
						></i>
					</div>
					<div className="pt-5 "
					style={{maxHeight: "300px", // Set the maximum height as per your requirement
					overflowY: "auto"}}>
						<h1>
							<strong className="text-gray-700 text-[20px] mt-5">
								{eventDetails?.title}
							</strong>{" "}
						</h1>
						<p className=" text-gray-500">
							{eventDetails.start &&
								moment(eventDetails.start).format(
									"MMMM DD"
								)}{" "}
							-{" "}
							{eventDetails.end &&
								moment(eventDetails.end).format("MMMM DD")}
						</p>
						<p className=" text-gray-500 pt-3">
							{eventDetails?.description}
						</p>
					</div>
					{isEditModalOpen && (
						<EditEventModal
							isOpen={isEditModalOpen}
							onClose={() => {
								setIsEditModalOpen(false);
								onClose();
							}}
							eventDetails={eventDetails}
							reload={reload}
							e_Id={eventDetails?.id}
						/>

					)}
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default EventDetailModal;


